// REACT
import React, { useState } from "react"
// GATSBY
import { navigate } from "gatsby-link"
// MUI
import { makeStyles } from "@material-ui/core/styles"
import {
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"

// STYLES
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      display: "block",
      margin: theme.spacing(4, 2),
    },
    "& .MuiInputBase-root": {
      maxWidth: "100%",
    },
  },
  name: {
    "& .MuiInputBase-input": {
      width: "29ch",
    },
  },
  email: {
    "& .MuiInputBase-input": {
      width: "47ch",
    },
  },
  phone: {
    "& .MuiInputBase-input": {
      width: "29ch",
    },
  },
  unit: {
    "& .MuiInputBase-input": {
      width: "47ch",
    },
  },
  launchDate: {
    "& .MuiInputBase-input": {
      width: "29ch",
    },
  },
  request: {
    "& .MuiInputBase-input": {
      width: "76ch",
    },
  },
  button: {
    margin: theme.spacing(4, 2),
  },
}))

// FUNCTIONS
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

// COMPONENT FUNCTION
const Contact = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // STATE
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    unit: "",
    launchDate: "",
    request: "",
  })

  // HANDLERS
  const handleChange = event => {
    const { name, value } = event.target
    setState(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  // COMPONENT
  return (
    <Layout title="Spring Launch">
      <PageTitle title="SPRING LAUNCH" />

      <Typography paragraph style={{ marginBottom: "4rem" }}>
        Please use the below form to schedule your boat/motor/mooring launch
        this spring.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        Scheduling Notes
      </Typography>
      <List>
        <ListItem>
          <ListItemText>First responses scheduled first.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Wellfleet Harbor officially opens May 15.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Holiday, Saturday, and Sunday are not available.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Accounts must be current.</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            You are responsible for the date you choose. Your boat or mooring
            will be launched on or just before the date you give us. Just ensure
            you're around to attend to it after the launch date.
          </ListItemText>
        </ListItem>
      </List>

      <form
        className={classes.root}
        name="launch"
        method="post"
        action="/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="launch" />
        <input type="hidden" name="bot-field" onChange={handleChange} />
        <TextField
          variant="filled"
          name="name"
          label="Name"
          value={state.name}
          onChange={handleChange}
          required
          className={classes.name}
        />
        <TextField
          variant="filled"
          name="email"
          label="Email"
          value={state.email}
          onChange={handleChange}
          required
          className={classes.email}
        />
        <TextField
          variant="filled"
          name="phone"
          label="Phone"
          value={state.phone}
          onChange={handleChange}
          className={classes.phone}
        />
        <TextField
          variant="filled"
          name="unit"
          label="Unit"
          value={state.unit}
          placeholder="What needs to be made ready for launch or pick up?"
          onChange={handleChange}
          required
          className={classes.unit}
        />
        <TextField
          variant="filled"
          type="date"
          name="launchDate"
          label="Requested Launch Date"
          value={state.launchDate}
          onChange={handleChange}
          required
          className={classes.launchDate}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          variant="filled"
          name="request"
          label="Additional requests or services"
          value={state.request}
          placeholder="Anything else we may need to know... slip #, mooring location, launching location or any other additional work that you may require."
          onChange={handleChange}
          multiline
          rows="8"
          className={classes.request}
        />
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          className={classes.button}
        >
          Send
        </Button>
      </form>
    </Layout>
  )
}

export default Contact
